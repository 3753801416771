@import "../../../assets/vars";

.learning_persona_container {
  background: $White;
  border-radius: 8px;
  height: auto;
}

.headerContainer {
  border-bottom: 1px solid $Silver;
}

.FaceMovementValuesContainer {
  position: relative;
  width: 100%;
  height: 48px;
}

.FaceMovementValuesBack {
  position: absolute;
  width: 48px;
  height: 100%;
}
.Critical {
  color: $CarrotOrange;
  border-left: 2px solid $CarrotOrange;
}

.Completed {
  color: $persianGreen;
  border-left: 2px solid $persianGreen;
}

.CriticalBack {
  opacity: 0.2;
  background: linear-gradient(
    90deg,
    $CarrotOrange 0%,
    rgba(247, 145, 51, 0.916667) 72.42%,
    rgba(196, 196, 196, 0) 107.79%
  );
}

.CompletedBack {
  opacity: 0.2;
  background: linear-gradient(
    90deg,
    #66e5db 0%,
    rgba(109, 227, 217, 0.927083) 85.9%,
    rgba(196, 196, 196, 0) 107.79%
  );
}

.LearningPersonaLeftpanalContainer {
  border-right: 1px solid $Silver;
}
.ChartStyling {
  width: 80% !important;
}

.StackBarschartStyling {
  height: 70px;
}

.polarAreaAdjustment {
  width: 400px !important;
  height: 215px !important;
  margin-left: -90px !important;
}

.CompletedText{
  color: $persianGreen;
}

.CriticalText{
  color: $CarrotOrange;
}



// @include mediaQuery(
//   (
//     polarAreaAdjustment: (
//       height: 215,
//     ),
  
//   )
// );