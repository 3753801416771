@import '../../assets/main';
@import '../../assets/styleguide.scss';


.search {
    border: 1px solid rgba($color: $persianGreen, $alpha: 0.36);
    background-color: $white;
  }
  
  .AutoSearch{
    fieldset{
      top:0;
    }
    legend{
      display: none;
    }
  }
  .searchBarTextBelow{
    font-size: 12px;
  }