@import '../../../assets/styleguide';
@import '../../../assets/vars';

.signupFooterContiner{
    display: flex;
    justify-content: space-evenly;
    bottom: 0%;
}

.signupFooter{
    font-size: $font_12;
    color : $Firefly
}