@import "../../../assets/main";

.learning_behavior_container {
    background: $White;
    border-radius: 8px;
    height: auto;
}

.headerContainer {
    border-bottom: 1px solid $Silver;
  }

.lBehaviorLeftContainer{
    min-height: 554px;
}

.lBehaviorRightContainer{
    border-left: 0.1px solid $Silver;
}

.textColor{
    color: $SurfieGreen;
}

.font_bold_600{
    font-weight: 600;
}

.avtarImage{
    width: 73.65px !important;
    height: 70.87px !important;   
}

.progressText{
    font-size: 9.73px !important;
    color:$gunPowder;
    font-weight: 600 !important;
}

.visitedSessionCaption{
    font-weight: 600;
    font-size: 14px;
    color: $eclipse;
    opacity: 0.9;
}

.visitedSessionHead{
    font-weight: bold;
    color: $gunPowder;
    opacity: 0.9;
}

.visitedSessionText{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: $gunPowder;
    opacity: 0.9;
    word-break: break-all;
}

.HorzontalbarHeading{
    font-style: normal;
    font-weight: 600;
    color: $eclipse;
    opacity: 0.9;
}

.StackBarschartStyling {
    height: 70px;
  }

.BrowsingContainer{
    height: 200px;
}

.BrowsingBar{
    width: 80px;
    color: $white;
    display: table-cell;
    vertical-align: middle;
}

.Width80{
    width: 80px;
}
