@import '../../assets/main';

/* Overview */

.overviewSection {
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.12);
}

.overviewFirstSection {
  min-height: 500px;
}

.errorIcon {
  height: 200px !important;
  width: 200px !important;
  @extend .primaryColor;
}

/* Learning Plan */

.leaningPlanSection {
  grid-template-columns: repeat(auto-fill, 350px);
  grid-gap: 24px 20px;
}

.addLearningPlanBox {
  height: 250px;
  width: 350px;
}

/* Learning Plans card */

@mixin timelineProps($selector, $color, $fontSize, $mg) {
  .#{$selector} {
    color: $color;
    font-size: #{$fontSize}px !important;
    margin: $mg;
    @content;
  }
}

@mixin cardTextProps($selector, $color) {
  .#{$selector} {
    color: $color !important;
    @content;
  }
}

@mixin cardVariant($selector, $bgcolor, $color, $item-color) {
  .#{$selector} {
    background: $bgcolor;
    color: $color;
    border-color: $color !important;
    @include timelineProps(cardTimelineActiveItem, $color, 13, 0);
    @include timelineProps(cardTimelineInertItem, $item-color, 13, 0);

    .iconButton {
      color: $color !important;
      font-size: 50px;
      border: 1px solid;
      padding: 12px;
    }
    @content;
  }
}

@include cardVariant(
  activeCardVariant,
  rgba(218, 235, 233, 1),
  $persianGreen,
  $White
);

@include cardVariant(
  inactiveCardVariant,
  rgba(196, 196, 196, 0.25),
  $nobel,
  $veryLightGrey
) {
  .learningPlanIcon {
    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(157deg)
      brightness(100%) contrast(100%);
  }
}

.learningPlanCard {
  height: 250px;
  width: 350px;
  border-top: 9px solid;
  border-radius: 8px;
  transition: 0.5s ease;

  .cardContentContainer {
    height: 132px;
  }

  @include cardTextProps(cardCoursesCnt, $Nero);
  @include cardTextProps(cardCourses, $dimGrey2);
  @include cardTextProps(cardDesc, $charcoal);
}

/* Learning Plan Preview */

@mixin ModalBoxDeclarations($selector, $width...) {
  .#{$selector} {
    width: #{nth($width, 1)}vw;
    min-width: #{nth($width, 2)}px;
    max-width: #{nth($width, 3)}px;
    @content;
  }
}

@include ModalBoxDeclarations(planPreviewModalBox, 65, 615, 888px) {
  height: 560px;
  overflow-y: auto;

  .modalChip {
    background: $persianGreen;
    color: $white;
  }

  .heading {
    color: $Firefly;
    font-weight: 900;
  }

  .listItemIcon {
    min-width: 0;
  }
}

/* Learning Plans left panel */

.searchContent {
  min-height: 50px;
  border: 1px solid $persianGreen;
  .divider {
    height: 48px;
  }
}

.subHeading {
  color: $gunPowder;
}

.leftPanelCourses {
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  grid-gap: 0 24px;
  place-items: center;
}

/* Learning Plans modal box */

@function linearGradient($reqPercent) {
  @return linear-gradient(
    131.62deg,
    $robinsEggBlue -6.12%,
    $persianGreen calc($reqPercent * 1%)
  );
}

@include ModalBoxDeclarations(planDetailsModalBox, 72.768, 300, 993) {
  .header {
    padding: 15px 27px 0 29px;
  }

  .continueBtn {
    margin: 0 27px 0 11px;
  }
}

.planDetailsForm {
  max-height: 517px;
  overflow-y: auto;
}

.planDetailsForm::-webkit-scrollbar {
  width: 5px;
}

.planDetailsForm::-webkit-scrollbar-thumb {
  background: $DimGray;
}

@include timelineProps(modalTimelineActiveItem, $persianGreen, 10, 0 4px) {
  background: linearGradient(0);
  box-shadow: 0px 0px 4px $persianGreen;
}

@include timelineProps(modalTimelineInertItem, $ghostWhite, 15, 0 3px 2px 3px);

.inputGroup {
  width: 425px;
}

/* Learning Plans right panel */

@mixin cardProps($selector, $color) {
  .#{$selector} {
    border-left-color: $color !important;
    color: $color;
    @content;
  }
}

$cardContent: (
  videoCard: $jazzberryJam,
  podcastCard: $Cerulean,
  audioCard: $darkGoldenrod,
  articleCard: $indigo,
  coursesCard: $islamicGreen,
);

.rightPanel {
  width: 30%;
  z-index: 1 !important;
}

.iconActive {
  @extend .primaryColor;
  background-color: rgba($color: $persianGreen, $alpha: 0.1);
}

.iconInactive {
  color: $santasGrey;
}

.filterControlHeading {
  padding: 19px 25px 0 30px;
}

.moduleCard {
  height: 100px;
  overflow: unset !important;

  .border {
    border-image: linearGradient(82.96);
    border-image-slice: 1;
  }
}

.viewAgendaIcon {
  @extend .iconInactive;
}

.moduleContentHeading {
  padding: 19px 35px 31px 20px;
}

.formLayout {
  padding: 32px 21px 0;
}

.backBtn {
  background-color: transparent !important;
  margin-bottom: 32px !important;
}

.inputGroupLabel {
  color: $surfiGreen !important;
}

.inputGroupBorder {
  border-color: $surfiGreen !important;
}

.customizedContentCard {
  height: 200px;

  @each $key, $value in $cardContent {
    @include cardProps($key, $value);
  }

  @include cardTextProps(cardDesc, $dimGrey2);
}

/* Media Query */

@include mediaQuery(
  (
    overviewFirstSection: (
      min-height: 500,
    ),
    errorIcon: (
      height: 200,
    ),
    cardContentContainer: (
      height: 132,
    ),
    planPreviewModalBox: (
      height: 560,
    ),
    searchContent: (
      min-height: 50,
    ),
    divider: (
      height: 48,
    ),
    planDetailsForm: (
      max-height: 517,
    ),
    moduleCard: (
      height: 100,
    ),
    customizedContentCard: (
      height: 200,
    ),
  )
);
