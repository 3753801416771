@import "../../../../assets/main";



.columnsStyling {
    border: none !important;
    .TableData {
      color: $eclipse;
      padding-top: 12px
      ;
    }
  }