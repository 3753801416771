@import '../../assets/main';

.viewProfile {
  height: 92px;
  background-color: $white;
  padding: 0px 30px;
}

@include mediaQuery(
  (
    viewProfile: (
      height: 92px,
    ),
  )
);
