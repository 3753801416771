@import '../../assets/main';

.privacyPolicy {
  overflow: auto;
  height: 100vh;
}

.li {
  color: rgb(3, 212, 212);
}
