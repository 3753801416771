@import '../../../../assets/main';

.Button1 {
  border-radius: 15px !important;
  background: $persianGreen !important;
  // padding: 5px 50px !important;
  min-width: 178px !important;
  box-shadow: none !important;
  .text {
    font-weight: 700;
    text-transform: none;
    line-height: 28px;
  }
}

.Button2 {
  width: 240px !important;
  height: 52px !important;
  border-radius: 15px !important;
  margin-top: 22px !important;
  margin-left: 27px !important;
  background: $persianGreen;

  .text {
    font-weight: 700 !important;
    text-transform: none !important;
  }
}
