@import '../../assets/main';
.list {
  padding-top: 15px !important;

  .itemButton {
    padding: 10px 25px 15px 12px !important;
  }

  .itemButton:focus,.sidebarItemStyle {
    background: rgba(125, 125, 125, 0.2);
    border-left: 4px solid;
    border-image: linear-gradient(
      131.62deg,
      $robinsEggBlue -6.12%,
      $persianGreen 0%
    );
    border-image-slice: 1;
  }

  .itemIcon {
    min-width: 44px;
  }

  .dropdownItemButton {
    padding-left: 55px;
  }

  .dropdownItemButton:focus,.sidebarSubItems {
    @extend .primaryColor;
    background: rgba($color: $persianGreen, $alpha: 0.1);
    font-weight: 400;
  }
  
}

.doubleArrowIconButton {
  height: 32px !important;
  width: 32px !important;
  color: $black;
}

//Media Query

@include mediaQuery(
  (
    doubleArrowIconButton: (
      height: 32,
    ),
  )
);
