@import '../../assets/main';

.ModalContainer {
  height: calc(100% - 42px);
  // width: calc(100% - 200px);
  outline: none;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);

  .BoxContainer {
    background: $white;
    border-top: 10px solid $persianGreen;
  }
}

.uploadBtnWrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;

  .fileBtn {
    background: $white;
    border: 1px solid $persianGreen;
    border-radius: 5px;
    &:hover {
      background: rgba(102, 229, 219, 0.1);
    }
  }

  .icon {
    color: $persianGreen;
    font-size: 36px;
  }
}

.uploadBtnWrapper input[type='file'] {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}

.InviteByEmail {
  background-color: $white;
  border: 1px solid $persianGreen;
  border-radius: 5px;

  .EmailIcon {
    color: $persianGreen;
    font-size: 36px;
  }

  &:hover {
    background: rgba(102, 229, 219, 0.1);
  }
}

.ButtonContainer {
  position: absolute;
  bottom: 30px;
  right: 21px;
}

//CSVUploadFaild

.UploadIconContainer {
  width: 165px;
  position: relative;

  .UploadCloseContainer {
    position: absolute;
    top: 20px;
    right: -20px;
    cursor: pointer;
  }

  .UploadCloseIcon {
    background: $RadicalRed;
    border-radius: 100%;
    width: 36px;
    height: 36px;
  }
}

.UploadFailedText {
  color: $Crimson;
}

.UploadFailedDescription {
  color: $gunPowder;
}

//EMAIL INVITE
.TextField {
  height: 40px !important;
  width: 100%;
  label {
    font-size: 14px !important;
    padding-left: 10px;
  }
  input {
    background: $white !important;
    font-size: 14px !important;
    // padding-left: 20px !important;
    // padding-right: 20px !important;
    padding: 11px 9px !important;
  }

  fieldset {
    border-radius: 4px;
    border-color: rgba(0, 0, 0, 0.23);
    top: 0px;

    legend {
      display: none;
    }
  }
}

.InviteContainer {
  height: 55vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.allocation {
  box-shadow: 0px 2px 18px rgba(130, 130, 130, 0.25);
  .heading {
    color: $surfiGreen !important;
  }
  .tableContainer {
    padding: 0 65px;
  }
}

.santasGrey {
  color: $santasGrey;
}

.allocationActionsMenu {
  background: $persianGreen;
  div {
    letter-spacing: 0.7px;
  }
}
.allocationTableRow td {
  color: $eclipse !important;
  font-size: 14px;
  font-weight: normal;
}

.requestApproveContainer {
  outline: none;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);

  .BoxContainer {
    background: $white;
  }

  .leaveMailContainer {
    padding: 20px 10px;
  }

  .cancelButton {
    color: $PersianGreen !important;
    text-transform: capitalize !important;
  }

  .continueBtn {
    color: $white;
    background-color: $PersianGreen !important;
    margin-left: 8px !important;
    text-transform: capitalize !important;
  }

  .btnDivContainer {
    margin-top: 20px;
  }
}

.pendingRequestsRow {
  background: rgba($color: $Crusta, $alpha: 0.18);
}
.memberDetails {
  color: $darkCyan;
}

.detailsData {
  font-weight: 100;
}
