@import '../../assets/main';

.signInPageContainer{
  width: 100vw;
  height: calc(100vh - 8vh);
  overflow-y: scroll;
  position: relative;
  overflow-x:hidden;
}

.signInPage {
  background: rgba(0, 128, 0, 0.6) url('../../assets/images/background.png')
    no-repeat;
  background-size: 100% 100%;
}
