@import '../../assets/main';

.header {
  background-color: $WhiteSmoke;
  box-shadow: 0px 2px 4px $russianBlack;
  height: 60px;
  z-index: 999;
}

.ahuraSymbol {
  letter-spacing: 7px;
}

.helpMenu {
  top: 65px !important;
}

.headerDivider {
  border-left: 1px solid $lightGray;
  height: 26px;
}

.userAccount {
  margin-top: -4px;
  color: $grey;
}

.AutoSearchContainer {
  width: 290px;
}

.AutoSearch {
  fieldset {
    top: 0;
  }
  legend {
    display: none;
  }
}

.WatchTutBtn {
  left: 5%;
  color: #00a89a !important;
  background-color: rgba(0, 168, 154, 0.12) !important;
  box-shadow: none !important;
  div {
    font-size: 14px;
  }
}

.HelpBtn {
  color: #00a89a !important;
  background-color: rgba(0, 168, 154, 0.12) !important;
  box-shadow: none !important;
  div {
    font-size: 14px;
  }
}

.adminBtn {
  left: 7%;
  color: #00a89a !important;
  background-color: rgba(0, 168, 154, 0.12) !important;
  box-shadow: none !important;
  div {
    font-size: 14px;
  }
}

.popperClass {
  width: 35% !important;
  left: -3.5rem !important;
  z-index: 49 !important;
}

.clickAway {
  width: 100%;
  background-color: #ffffff;
  border-radius: 0.375rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.badge {
  padding: 0;
}

.badgeIcon {
  margin-top: 4px;
  margin-right: 5px;
  padding: 4.5px;
}

.leaveModalContainer {
  outline: none;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);

  .BoxContainer {
    background: $white;
  }

  .leaveMailContainer {
    padding: 20px 10px;
  }

  .cancelButton {
    color: $PersianGreen !important;
    text-transform: capitalize !important;
  }

  .continueBtn {
    color: $white;
    background-color: $PersianGreen !important;
    margin-left: 8px !important;
    text-transform: capitalize !important;
  }

  .btnDivContainer {
    margin-top: 20px;
  }
}

@include mediaQuery(
  (
    header: (
      height: 60,
    ),
    headerDivider: (
      height: 26,
    ),
  )
);
