@import '../../../../assets/main';

.leftPanel {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  height: calc(100vh - 58px) !important;
  background: $white !important;
  border: 1px solid $gainsboro !important;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.247323) !important;

  .headerText {
    height: 24px !important;
    margin-left: 32px !important;
    margin-top: 29px !important;
    margin-bottom: 29px !important;
    font-family: Inter !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 20px !important;
    line-height: 24px !important;
    color: $martinique !important;
  }

  .subHeading {
    font-family: Inter !important;
    font-size: 18px !important;
    line-height: 22px !important;
    color: $martinique !important;
  }

  .subText {
    font-family: Inter !important;
    font-size: 16px !important;
    line-height: 19px !important;
    font-weight: 700 !important;
    color: $martinique !important;
  }

  .subListText {
    font-family: Inter !important;
    font-size: 14px !important;
    line-height: 16px !important;
    color: $martinique !important;
  }

  .listItemIcon {
    margin-left: 40px !important;
    color: $black !important;
    min-width: 46px !important;
  }

  .badge {
    min-width: 43px !important;
    height: 25px !important;
    font-size: 16px !important;
    border-radius: 8px !important;
    background-color: $nobel_1 !important;
  }

  .badgeSecond {
    min-width: 40px !important;
    height: 20px !important;
    font-size: 14px !important;
    border-radius: 8px !important;
    background-color: $nobel_1 !important;
  }
}

.leftPanel::-webkit-scrollbar {
  width: 2px !important;
}

.leftPanel::-webkit-scrollbar-track {
  background: $silver !important;
}

.leftPanel::-webkit-scrollbar-thumb {
  background: $persianGreen !important;
}

.leftPanel::-webkit-scrollbar-thumb:hover {
  background: $white !important;
}

.ml-20 {
  margin-left: 5rem !important;
}
