@import '../../../assets/main';
.HorizontalLine {
    opacity: 0.15;
    width: 95%;
    color: gray;
}

.TextCountViews {
    font-size: 18px;
    font-weight: 500;
    color: black;
}

.TextCountViewsDescription {
    font-size: 10px;
    font-weight: 500;
    opacity: 0.6;
}

.TextCountPercentage {
    color: #00A89A;
    font-size: 17px;
    font-weight: 400;
}

.CommonHeadingCommunity {
    color: black;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
}

.TopLearnersName {
    margin-left: 0;
    color: #00A89A;
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
}

.TopLearnersColumn2 {
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: $persianGreen;

}
.time {
    font-size: 10px !important;
    .WatchIcon {
        height: 15px !important;
        width: 15px !important;
    }
}

.TopLearnersColumn2Description {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: black;
}

.GreenCardCourses {
    color: #00A89A;
}

.TopLearnersColumn3 {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: $black;
}

.BlackClock {
    color: black !important;
}

.TrendingTopics {
    font-family: Inter;
    font-style: normal;
    font-weight: 340;
    font-size: 18px;
    line-height: 17px;
    color: black;
}

.CommunityPanals {
    width: 100%;
    overflow: auto;
    height: 23em;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.topLearners{
    margin-top: -16px !important;
}

.TrendingListDataContainer {
    border-bottom : 1px solid $WhiteSmoke;
}
.learnerHeader {
    color : $echoBlue !important;
    font-size: 12px ;
    margin-left: 2rem;
}