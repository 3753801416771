@import '../../assets/main';

.homepage {
  z-index: -1;
}

.classForHead {
  font-weight: 600;
  font-size: 14px;
}
