@import '../../assets/main';

.Container {
  background-color: $Nero;
}

.TextBox {
  label {
    color: $white !important;
  }

  input {
    color: $white !important;
    border-radius: 50px;
  }

  fieldset {
    border: 1px solid $nobel !important;
    border-radius: 15px;
  }
}

.ButtonInvite {
  border-radius: 15px !important;
  background: $persianGreen !important;
  font-weight: bold !important;
  line-height: 28px !important;
  box-shadow: none !important;
  text-transform: none !important;
  width: 100%;
  height: 45px;
}

.LandingPageCover {
  height: 100%;
  width: 100%;
}

.videoPlayAction {
  position: absolute;
  left: calc(50% - 30px);
  top: calc(50% - 30px);
  cursor: pointer;
}

.videoPlayAction span {
  height: 60px;
  width: 60px;
  background: $persianGreen;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  animation: pulse 2s infinite;
  box-shadow: 0 0 0 rgba(0, 36, 203, 0.9);
}

.videoPlayAction span:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 15px solid $white;
  border-bottom: 10px solid transparent;
  left: 25px;
  top: 20px;
}

@keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba($persianGreen, 0.7);
    }
  
    70% {
        box-shadow: 0 0 0 20px rgba(0, 176, 255, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(0, 176, 255, 0);
    }
  }

.CapabilityContainer {
  background-color: $Nero;
}

.CapabilityCourseContainer {
  background: linear-gradient(144.37deg, $eclipse 7.57%, $Nero 155.76%);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  position: relative;
  overflow: hidden;
  height: 100%;
  cursor: pointer;

  &:hover {
    .CapabilityCourseActivate {
      transform: translateX(0);
    }
  }
}

.CapabilityCourseActivate {
  background: linear-gradient(
    131.62deg,
    $RobinsEggBlue -6.12%,
    $persianGreen 82.96%
  );
  mix-blend-mode: normal;
  opacity: 0.9;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.3s ease-in-out;
  transform: translateX(calc(-100% - 10px));
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}

.LandingPageCourseIcon {
  width: 30px !important;
}

.TestimonialContainer {
  background: $black;
  overflow: auto;
}

.TestimonialCarouselMain {
  max-width: 1170px;
  margin: auto;
  .carousel-indicators {
    bottom: -80px;
    li {
      background: $Gainsboro;
      width: 15px;
      height: 15px;
      margin: 0 10px;
      border-radius: 50%;
      opacity: 1;
      border: none;
      box-sizing: border-box;
      &.active {
        background: linear-gradient(
          180deg,
          $RobinsEggBlue 0%,
          $persianGreen 100%
        );
      }
    }
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $nobel;
    opacity: 1;
    top: calc(50% - 19px);
    transition: 0.3s ease-in-out;
  }
}

.TestimonialImg img {
  width: 380px;
  height: 400px;
  position: relative;
  z-index: 1;
}

.TestimonialText {
  background: linear-gradient(205.06deg, $eclipse 29.82%, $Nero 138.45%);
  min-height: 355px;
  margin-left: -10px;
  border-radius: 15px;
}

.videoPlayImage {
  width: 100%;
  height: 100%;
}

.BackToTop {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.Activate {
  background: linear-gradient(180deg, $eclipse, $Nero);
  opacity: 0.8;
  box-shadow: 0 2px 4px rgb(0 0 0 / 15%);
}

.ButtonActivate {
  background: linear-gradient(
    131.62deg,
    $RobinsEggBlue -6.12%,
    $persianGreen 82.96%
  );
  border-radius: 15px !important;
  color: $white;
  min-height: 50px;
}
