@import '../../../assets/main';
@import '../../../assets/vars';

.QuickStatsLeftChartContainer {
    width : 90%;
}

 .RightReportStatsPanal{
    width : 30% !important;
}
.LeftReportStatsPanal{
    border-right : 2px solid $PattensBlue;
    width : 70% !important;
}

.infoCard{
    right: 35%; 
}

.CardHeading {
    color :$surfiGreen;
}