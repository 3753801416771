$white: #ffffff;
$silver: #BCBCBC;
$gainsboro: #DDDDDD;
$charcoal:"#434343";
$nobel: #999999;
$martinique: #3D3C40;
$persianGreen: #00A89A;
$black: #000000;
$nobel_1: #969696;
$tuna: #3a3a45;
$eclipse: #3E3E3E;

// FONT SIZE
$font_12: 12px;
$font_16: 16px;
$font_18: 18px;
$font_20: 20px;
$font_24: 24px;

// FONT_FAMIY
$font_Poppins_sans_serif: "Poppins", sans-serif;
$font_SF_Pro_Display: "SF Pro Display";