@import '../../../assets/main';
@import '../../../assets/styleguide.scss';

.modalFieldsContainer {
  .modalFields {
    border-radius: 4px;
    position: relative;
    border: 1px solid $black !important;
    outline: none !important;
    color: $black;
    padding: 10.5px;
    font-size: 14px;
  }
  fieldset {
    display: none;
  }
}

.modelFooter {
  border-top: none !important;
  margin-top: 395px !important;
}

.cancelButton {
  color: $PersianGreen !important;
  text-transform: capitalize !important;
}
.continueBtn {
  color: $white;
  background-color: $PersianGreen !important;
  margin-left: 8px !important;
  text-transform: capitalize !important;
}

.backgroundColor {
  background-color: $PersianGreen !important;
}

.inputDiv {
  margin-top: 8% !important;
}

.accountText {
  margin-left: 25% !important;
  margin-bottom: 8px;
  margin-top: 22px;
}

// Upload Picture Css here
.uploadAvatar {
  width: 150px !important;
  height: 150px !important;
  background-color: $Firefly !important;
}

.backBtn {
  width: 33px;
  height: 33px;
  background-color: $HitGrey;
  position: absolute;
  left: 17px;
  border-radius: 4px;
  bottom: 3%;
  cursor: pointer;
}

.backBtnInside {
  margin-left: 9px !important;
  margin-top: 4px !important;
  color: $white !important;
}

.footer {
  position: absolute;
  bottom: 1%;
  right: 12px;
}

// .avatarDiv {
//   padding-top: 15%;
// }

.avatarText {
  font-size: 80px;
}

.skipNow {
  color: $PersianGreen;
  padding-top: 12px;
}

.radioDiv {
  margin-left: 23%;
}

.ModalContainer {
  width: 70%;
  height: 95%;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: $white;
  transform: translate(-50%, -50%);
  border-top: 10px solid #00a89a;
  border-radius: 4px;
  box-shadow: 24;
}
.FormContainer {
  overflow-y: auto;
  height: 60vh;
}
.para {
  margin-bottom: 10px;
  font-size: 15px;
}
.link {
  font-weight: 600;
  text-decoration: none;
  color: black;
}

//OTPVerification

.Logo {
  max-width: 200px;
  max-height: 60px;
}

.focusStyle {
  outline: cadetblue;
}

.otpBox > input {
  width: 60px !important;
  height: 60px;
  background-color: $white;
  color: $black;
  border: 2px solid $nobel !important;
  margin: 10px;
}

.otpBox input:focus {
  border: 2px solid $Tradewind !important;
}

.imageDimensions {
  width: 150px !important;
  height: 150px !important;
  margin: 0px auto;
}

.errorText {
  color: $PersianRed;
  font-weight: 400;
  font-size: 0.75rem;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}
