@import '../../assets/vars';
@import '../../assets/main';

/*Manager Dashboard SCSS file*/
.managerHeading {
  width: 100%;
  height: 110px;
  border: 1px solid $LightSlateBlue;
  border-radius: 6px;
  background-color: $BlueChalk;
}

.background {
  position: absolute;
  width: 100%;
  height: 110px;
  opacity: 0.2;
  background-color: $LightSlateBlue;
}

.firstText {
  margin-top: 26px;
  color: $PurpleHeart;
}

.secondText {
  margin-top: 26px;
  color: $PurpleHeart !important;
  font-size: 14px;
  font-weight: 500;
}

.QuickStatsSection {
  border-bottom: 0.5px solid $Silver;
}

.LicenseDistributionContainer {
  background-color: $white;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  height: 580px;
}

.LicenseDistributionChart {
  height: 350px;
}

.AverageLearningVolumeContainer {
  background-color: $white;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  height: 580px;
}

.LearningPlanContainer {
  background-color: $white;
  border-radius: 8px;
}

.LearningPlanChartStyle {
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.12);
  height: 300px;
}

.LearningPlanHeading {
  font-weight: 600;
  line-height: 22px;
  text-decoration-line: underline;
  color: $persianGreen;
}

.QuickStatsContainer {
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.managerHeading {
  width: 100%;
  height: 110px;
  border: 1px solid $LightSlateBlue;
  border-radius: 6px;
  background-color: $BlueChalk;
}

.background {
  position: absolute;
  width: 100%;
  height: 110px;
  opacity: 0.2;
  background-color: $LightSlateBlue;
}

.infoIcon {
  font-size: 16px !important;
}

.firstText {
  margin-top: 26px;
  color: $PurpleHeart;
}

.secondText {
  margin-top: 26px;
  color: $PurpleHeart !important;
  font-size: 14px;
  font-weight: 500;
}

.QuickStatsSection {
  border-bottom: 0.5px solid $Silver;
}

.LinearProgessBar {
  height: 20px;
  background-color: transparent;
  border-radius: 100px;
}


.learningPlanTable {
  margin-top: 2% !important;
  padding: 35px !important;
}

.headingCls {
  color: maroon;
}

@include mediaQuery((LicenseDistributionContainer:(height: 580,
    ),

    LicenseDistributionChart:(height: 350,
    ),
    AverageLearningVolumeContainer:(height: 580,
    ),

    LearningPlanChartStyle: (height: 300,
    ),

    LearningPlanHeading:(line-height: 22,
    ),
    managerHeading: (height: 110,
    ),
    background :(height:110),
  ))