@import '../../assets/main';

//Actionable Insights

.ActionableInsightsContainer {
  background-color: $white;
  box-shadow: 0px 2.39655px 23.9655px rgba(0, 0, 0, 0.12);
  padding: 30px;
  margin: 50px;
  border-radius: 7.18965px;
}

.ChartTitle {
  color: rgba(2, 130, 119, 1);
  font-weight: 600;
}

.InsightsContainer {
  border-radius: 8px;
  padding: 31px 0px 32px 13px;
  background: rgba(102, 229, 219, 0.06);
  box-shadow: 0px 2px 18px rgba(130, 130, 130, 0.25);
  color: $black;
}

.ActionableInsights {
  font-family: sans-serif;
  position: relative;
  font-style: normal;
  font-weight: 600;
  margin: 30px;
  margin-top: 50px;
}

.CardIconStyle {
  color: $persianGreen;
}

.CardHeadingStyle {
  color: #57575c;
  margin-left: 5px;
}

.CardTextStyle {
  color: $eclipse;
  margin-left: 50px;
}

.actionableInsightsHouseCardHead {
  width: 200%;
  height: 6px;
}

.actionableInsightsHouseCardBody {
  width: 200%;
  height: 130px;

  .content {
    width: 120px;
  }
}

.actionableInsightsHouseCard1 {
  .actionableInsightsHouseCardHead {
    background: $persianGreen;
  }

  .actionableInsightsHouseCardBody {
    background: rgba($color: $persianGreen, $alpha: 0.18);
  }
}

.actionableInsightsHouseCard2 {
  .actionableInsightsHouseCardHead {
    background: $Cerulean;
  }

  .actionableInsightsHouseCardBody {
    background: rgba($color: $Cerulean, $alpha: 0.18);
  }
}

.actionableInsightsHouseCard3 {
  .actionableInsightsHouseCardHead {
    background: $Crusta;
  }

  .actionableInsightsHouseCardBody {
    background: rgba($color: $Crusta, $alpha: 0.18);
  }
}

.actionableInsightsHouseCard4 {
  .actionableInsightsHouseCardHead {
    background: $perfume;
  }

  .actionableInsightsHouseCardBody {
    background: rgba($color: $perfume, $alpha: 0.18);
  }
}

//Stats

@mixin statsTextProps($selector, $fs, $color) {
  .#{$selector} {
    font-size: #{$fs}vw;
    color: $color;
    @content;
  }
}

.stats {
  padding: 21px 24px 33px 33px;
  box-shadow: 0px 2px 18px rgba(130, 130, 130, 0.25);
}

.statsImgContainer {
  min-width: 180px;
}

@include statsTextProps(progressHeading, 1.7, $cyprus);

@include statsTextProps(performanceStat, 1.1713, $pineGreen);

.statsInfoIcon {
  margin: 0.17vw;
  font-size: 1.178vw !important;
  background: $persianGreen;
}

.profileStatContainer {
  padding-top: 2vw;
}

@include statsTextProps(profileCompletionStat, 1.1713, $santasGrey);

@include statsTextProps(profileCompletionItems, 1.025, $IrisBlue) {
  text-decoration: underline;
  text-decoration-color: $IrisBlue !important;
  @extend .fontWeight600;
}

.statsSeparatorIcon {
  font-size: 0.7vw !important;
  color: $IrisBlue !important;
}

// Higthlights

.highlights {
  background: #ffffff;
  height: auto;
  padding: 26px 33px 23px 37px;
  box-shadow: 0px 2px 18px rgba(130, 130, 130, 0.25);
  // box-shadow: 0px 2px 18px rgba(130, 130, 130, 0.25);
}

.highlightsSelect {
  color: $persianGreen !important;
}

.highlightsGraph {
  height: 200px !important;
  padding: 0px 15%;
  padding-top: 30px;
}

.highlightsHouseCardHead {
  width: 100%;
  height: 6px;
}

.highlightsHouseCardBody {
  width: 100%;
  height: 57px;

  .content {
    width: 80px;
  }
}

.highlightsHouseCard1 {
  .highlightsHouseCardHead {
    background: $persianGreen;
  }

  .highlightsHouseCardBody {
    background: rgba($color: $persianGreen, $alpha: 0.18);
    color: $persianGreen;
  }
}

.highlightsHouseCard2 {
  .highlightsHouseCardHead {
    background: $Crusta;
  }

  .highlightsHouseCardBody {
    background: rgba($color: $Crusta, $alpha: 0.18);
    color: $Crusta;
  }
}

.highlightsHouseCard3 {
  .highlightsHouseCardHead {
    background: $perfume;
  }

  .highlightsHouseCardBody {
    background: rgba($color: $perfume, $alpha: 0.18);
    color: $perfume;
  }
}

.chartBox {
  min-height: 200px;
}

/* Quick Stats */

.quickStats {
  background: $White;
  padding-bottom: 60px;

  .mainHeading {
    padding: 35px 0 19px 50px;
  }

  .subHeading1 {
    padding: 42px 0 33px 58px;
  }

  .subHeading2 {
    padding: 44px 0 0 58px;
  }

  .subHeading3 {
    padding: 37px 0 0 59px;
  }
}

.quickStatsInfoIcon {
  color: $White;
  background: $persianGreen;
  margin: 0 9px;
  font-size: 14px !important;
  border-radius: 15px;
}

.quickStatsFormControl {
  width: 57.8% !important;
  float: right;
  margin: 42px 54px 0 0 !important;
}

.quickStatsLineGraph {
  max-width: 32vw;
  max-height: 38vh;
}

.quickStatsBarGraph {
  height: 30vh;
  width: 38vw;
  margin-right: 5vw;
}

@mixin quickStatsTextDetails($selector, $paddingValue) {
  .#{$selector} {
    padding: $paddingValue;

    .text1 {
      line-height: 0.8 !important;
    }

    .text2 {
      color: $salem;
    }

    .text3 {
      color: $slateGrey;
    }

    .text4 {
      color: $freeSpeechRed;
    }
  }
}

@include quickStatsTextDetails(quickStatsLearningDetails, 16px 0 32px 51px);
@include quickStatsTextDetails(quickStatsEnjoymentDetails, 23px 0 44px 50px);

.quickStatsDoughnutGraph {
  margin: 0 30px 0 80px;

  .graphDimension {
    height: 200px !important;
    width: 350px !important;
  }
}

// Assigned Capabilities

.assignedCapabilities {
  background: $white;
  box-shadow: 0px 2px 18px rgba(130, 130, 130, 0.25);
  border-radius: 8px;

  .heading {
    color: $surfiGreen;
    font-weight: 500;
  }
}

// Sessions

.sessions {
  box-shadow: 0px 2px 18px rgba(130, 130, 130, 0.25);

  .heading {
    font-size: 22px;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 600;
  }

  .tableContainer {
    padding: 0 65px;
  }
}

.sessionsTableRow td {
  color: $eclipse !important;
  font-size: 14px;
  font-weight: normal;
}

.sessionsActionsMenu {
  background: $persianGreen;

  div {
    letter-spacing: 0.7px;
  }
}

// Expertise Journey

.expertiseJourneyContainer {
  background-color: $white;
}

.expertiseJourney {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
}

.customBadge {
  background-color: $persianGreen;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

// Create Account Menu

.accountMenu {
  width: 240px !important;
  max-height: 270px !important;
}

.disabledMenuIcon {
  color: #c6cccc !important;
  // background: #d8dbdb;
  width: 20px;
  height: 20px;
}

.menuIcon svg {
  fill: $persianGreen;
  width: 20px;
  height: 20px;
}

.createAccountBtn {
  border: 2px solid $persianGreen;
  height: 30px !important;
  width: 30px !important;
}

//Home Tables

.TableBodyCell {
  font-family: Inter !important;
  color: $eclipse;
  font-weight: normal;
}

.TableSelect {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.TableSelect {
  background: $persianGreen;
  color: white;
  width: 100%;
  font-size: 14px;
  padding: 6px 5px 6px 8px;
  border: none;
  outline: none;

  option {
    background: white;
    color: black;
    font-size: 18px;
    margin-bottom: 5px;
  }
}

.selectDownIcon {
  position: absolute;
  right: 5px;
  top: 4px;
  color: white;
}

.headingColor {
  color: #00a89a;
  font-weight: bold;
}

.color {
  color: #b8bdc2;
}

//highlights 2.0

.highlightDetailBox {
  border-radius: 2px;
  padding: 10px;
}

.positiveData {
  color: $salem !important;
}

.negativeData {
  color: $RadicalRed !important;
}

.capabilities {
  background: rgba($color: $spray, $alpha: 0.2);
}

.hours {
  background: rgba($color: $burntOrange, $alpha: 0.2);
}

.focus {
  background: rgba($color: $perfume, $alpha: 0.2);
}

//expertise 2.0
.hiExLoHr {
  background: $IrisBlue;
  color: $white;
  text-align: center;
  border-radius: 2px;
}

.hiExHiHr {
  background: $perfume;
  color: $white;
  border-radius: 2px;
}

.loExLoHr {
  background: $burntOrange;
  color: $white;
  border-radius: 2px;
}

.loExHiHr {
  background: $santasGrey;
  color: $white;
  border-radius: 2px;
}

.expertiseLegend {
  border: 1px solid $IrisBlue;
}

// Capabilities To Study

$cardModes: (
  cardMode1: $islamicGreen,
  cardMode2: $Cerulean,
  cardMode3: $jazzberryJam,
  cardMode4: $indigo,
  cardMode5: $darkGoldenrod,
);

@mixin cardModeProps($selector, $color) {
  .#{$selector} {
    border-color: $color;
    color: $color;
    background-color: rgba($color: $color, $alpha: 0.1) !important;
    @content;
  }
}

.noCapabilities {
  background: rgba($color: $persianGreen, $alpha: 0.1);
  height: 283px;
  border: 0.5px dashed $persianGreen;
  border-radius: 2px;
  color: $santasGrey;
}

.capabilityCard {
  flex: 0 0 auto;
  height: 225px;
  width: 390px;
  border-top: 9px solid;
  border-radius: 9px;
  background-color: $Silver;
}

.learningPlanHeading {
  color: $surfiGreen;
}

.search {
  border: 1px solid rgba($color: $persianGreen, $alpha: 1);
  border-radius: 4px;
  width: 35%;
}

.tablePagination {
  width: fit-content;
}

.tablePagination > div {
  align-items: baseline;
}

@each $key, $value in $cardModes {
  @include cardModeProps($key, $value);
}

.cardTextProp {
  color: $charcoal !important;
}

.cardDescDimension {
  height: 65px;
}

.cardIconButton {
  font-size: 50px !important;
  padding: 12px;
  border: 1px solid;
}

.slider {
  overflow-x: auto;
  gap: 15px;
  scroll-behavior: smooth;
}

.slider::-webkit-scrollbar {
  display: none;
}

.capabilitiesCount {
  color: $nobel;
}

@include mediaQuery(
  (
    quickStatsBarGraph: (
      height: 175,
    ),
    quickStatsLineGraph: (
      height: 176,
    ),
    highlightsHouseCardBody: (
      height: 57,
    ),
    actionableInsightsHouseCardBody: (
      height: 130,
    ),
    ActionableInsights: (
      height: 108,
    ),
    customBadge: (
      width: 24,
      height: 24,
    ),
    CA_icon_button svg:
      (
        width: 20,
        height: 20,
      ),
    CA_menu: (
      width: 230,
      height: 290,
    ),
    CA_menu_item: (
      height: 47,
    ),
    'CA_menu_item:hover': (
      height: 47,
    ),
  )
);

.ComingSoonContainer {
  width: 100%;
  opacity: 0.4;
}

.ComingSoon {
  position: absolute;
  top: 44%;
  width: 100%;
}

.CapabilitiesTab_Heading {
  color: black;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
}

/*Learner Message*/
.headingCls {
  color: black;
  font-weight: 600;
}

.subHeadingCls {
  color: darkred;
  margin-right: 5px;
  font-weight: 600;
}

.descriptionCls {
  color: darkslategrey;
}

.fixLoaderLayout {
  transform: translate(360%, 0);
  margin-top: 10px;
  height: 40px;
  width: 100%;
}

.fixTitleLayout {
  transform: translate(230%, 0);
  margin-top: 10px;
  height: 40px;
  width: 100%;
}
