@import "../../../../assets/main";
.rightPanel{
    height: calc(100vh - 58px);
    background: $white;
    overflow-y: scroll;
    
}

.overflowYscroll{
    overflow-y: auto !important;
}
