@import "../../assets/main";

.ManegerDashbordContainer {
    margin-top: 30px;
    
}
.UsageBarStyling {
    background-color: $BlueViolet !important;
}

