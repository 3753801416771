@import './vars';

// COLOR CLASS

.primaryColor {
  color: $persianGreen !important;
}

.secondaryColor {
  color: $tuna;
}

// FONT_SIZE_CLASS

.font_12 {
  font-size: 12px;
}

.font_16 {
  font-size: 16px !important;
}

.font_18 {
  font-size: 18px !important;
}

.font_20 {
  font-size: 20px !important;
}

.font_24 {
  font-size: 24px !important;
}

.font_30{
  font-size: 30px !important;
}

.font_34{
  font-size: 34px !important;
}

// FONT_WEIGHT_CLASS

.fontWeight500 {
  font-weight: 500 !important;
}

.fontWeight600 {
  font-weight: 600 !important;
}

.fade {
  opacity: 0.6;
}

.cursorPointer {
  cursor: pointer !important;
}

// Breakpoint variables

$lg: 1440;
$breakpoints: 1400, 1360;

// Required content in media query

@mixin reqContent($breakpoint, $collection) {
  @each $selector, $declaration in $collection {
    .#{$selector} {
      @each $property, $value in $declaration {
        #{$property}: #{calc($value / $lg) * $breakpoint}px !important;
      }
    }
  }
}

// Responsive breakpoint manager

@mixin mediaQuery($collection) {
  @each $breakpoint in $breakpoints {
    @if $breakpoint==1400 {
      @media (max-width: (#{calc($lg - 1)}px)) and (min-width: (#{$breakpoint}px)) {
        @include reqContent($breakpoint, $collection);
        @content;
      }
    }

    @if $breakpoint==1360 {
      @media (max-width: (#{$breakpoint}px)) {
        @include reqContent($breakpoint, $collection);
        @content;
      }
    }
  }
}

//Margin
.mt23{
  margin-top: 23px;
}

.mt30{
  margin-top: 30px !important;
}

.mb9{
  margin-bottom: 9px;
}

.mb17{
  margin-bottom: 17px;
}

.ml40{
  margin-left: 40px;
}

.ml90{
  margin-left: 90px;
}