@import "./../../../assets/main";

.SearchInput {
  padding: 0 !important;
  height: 41px !important;
  border: 1px solid $gainsboro;
  border-radius : 21px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.146061);
  width : 25rem; 
  fieldset {
    display: none;
  }
}
.SearchRoot {
  height: 41px;
}

.SelectInput {
  border: 1px solid $gainsboro;
  padding: 0 !important;
  height: 41px !important;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.146061);
  border-radius: 21px !important;
  width: 65%;
  fieldset {
    display: none;
  }
}
