@import '../../../../../assets/main';
.createCategoryLabel {
  margin-left: 0.5rem !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  color: $martinique !important;
}

.addIcon {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
}
