@import '../../assets/main';

.infoPage {
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  height: 100vh; /* Full viewport height */
  z-index: -1;
}

.infoDetails {
  padding: 35px 60px 70px 50px;
}

.infoStatus {
  color: $santasGrey;
}

.infoProgress {
  height: 10px;
  border-radius: 25px;
}

.supportingText {
  margin-top: 30px;
  margin-bottom: 20px;
}

.avatarTextContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start; /* Aligning both the avatar and content at the top */
  gap: 30px; /* Space between avatar and supporting text */
}

.avatarContainer {
  margin-right: 20px;
}

.avatar {
  width: 320px; /* Increased size for Avatar */
  height: 320px;
  border-radius: 50%;
}

.rightSideContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content to the top */
  align-items: flex-start; /* Ensures content starts from the left and is vertically aligned */
  width: 100%;
  position: relative;
}

.supportingTextContent {
  font-size: 24px;
  font-weight: bolder !important;
  color: #000;
  // max-width: 600px;
  margin: 0;
  text-align: justify;
  margin-bottom: 20px; /* Add some margin below the supporting text */
}


.cardContainer {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 30px;
  width: 100%; /* Take up full available width */
  align-items: flex-start;
}

.actionCard {
  width: 100%;
  height: 240px;
  background-color: #d3d3d3;
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;

  /* Card responsiveness */
  @media (min-width: 768px) {
    width: 45%; /* For medium screens, cards take up 45% of the container width */
  }

  @media (min-width: 1200px) {
    width: 22%; /* For large screens, cards will take up 22% of the container width */
  }
}

.actionCard:hover {
  transform: translateY(-5px);
}

.cardText {
  font-weight: 600;
  color: #333;
  text-align: center;
  font-size: 16px;
  padding: 10px;
  margin: 0;
}

.cardText:hover {
  color: #000;
}

.cardContent {
  margin-top: 30px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.cardTextContent {
  font-size: 16px;
  color: #333;
  line-height: 1.6;
  margin-bottom: 15px;
}

.cardTextContent p {
  margin-bottom: 15px;
  font-size: 16px;
  color: #333;
}

/* Full-screen layout */
@media (min-width: 1200px) {
  .actionCard {
    height: 300px; /* Increased height for large screens */
  }
}

/* Medium screens (Tablets, smaller laptops) */
@media (min-width: 768px) and (max-width: 1199px) {
  .actionCard {
    height: 250px;
  }
}

/* Smaller screens (Mobile) */
@media (max-width: 767px) {
  .actionCard {
    width: 100%; /* Make cards full width on small screens */
    height: 200px;
  }
}
