@import '../../assets/main';

.focus {
  background: $white;
  box-shadow: 0px 2px 18px rgba(130, 130, 130, 0.25);
  border-radius: 8px;
}

//visited URLs
.visitedURLs {
  background: $white;
  box-shadow: 0px 2px 18px rgba(130, 130, 130, 0.25);
  border-radius: 8px;
}

.fontRed {
  color: $freeSpeechRed;
}

.TableSelect {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.TableSelect {
  background: $persianGreen;
  color: white;
  width: 100%;
  font-size: 14px;
  padding: 6px 5px 6px 8px;
  border: none;
  outline: none;

  option {
    background: white;
    color: black;
    font-size: 18px;
    margin-bottom: 5px;
  }
}

.selectDownIcon {
  position: absolute;
  right: 5px;
  top: 4px;
  color: white;
}

.logo {
  width: 95px;
  height: 100%;
}

.sessionsCard {
  border-left: 6px solid $persianGreen;
  border-radius: 9px;
  background-color: $lightCyan;
  box-shadow: 0px 2px 18px rgba(130, 130, 130, 0.25);
}

.autoStoppedCls {
  background-color: $putty;
  font-size: 20px;
  border-radius: 10px;
  color: $white;
  width: 40%;
}