@import '../../assets/main';
@import '../../assets/styleguide.scss';



.accounts {
  color: $persianGreen;
  font-size: 16px;
  margin-bottom: 0px;
}

.linearProgress {
  height: 2px !important;
  color: $persianGreen;
}

.card1 {
  border: 2px dashed $TurquoiseBlue;

}

.iconText {
  margin-top: 50%;
}

.addIcon {
  color: $TurquoiseBlue;
  font-size: 40px !important;
  cursor: pointer;
}

.card2 {
  border-bottom: 4px solid $persianGreen;
  height: 281px;
}

.moreHorizonIcon {
  margin-left: 89%;
  margin-top: -24px;
  font-size: 34px !important;
  color: $persianGreen;
}

.imageStyle {
  height: 80px;
  width: 80px;
}

.secoundImg {
  position: absolute;
  margin-left: 85px;
  margin-top: -26px;
}

.personalProfile {
  text-align: center;
  margin: 0;
  font-size: 14px;
  color: #00d3ca;
}

.para {
  text-align: center;
  margin: 0;
  font-size: 14px;
}

.mainDiv {
  background-color: $white;
  height: 100%;
}

.colorAddAccount {
  color: $RobinsEggBlue;
}

.selectBoxDiv {
  margin-top: -35px !important;
  padding: 16px !important;
}

/* Media Query */

@include mediaQuery(
  (
    imageStyle: (
      min-height: 80,
    ),
  )
);
