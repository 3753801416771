@import '../../../assets/main';

/* CourseCardV2.scss */

.card-container {
  flex: 1;
  margin: 10px; /* Reduced horizontal and vertical margin */
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
  cursor: pointer;
  max-width: 400px; /* Adjust the maximum card width */

  &:hover {
    transform: scale(1.02);
  }

  .card-header {
    background-color: #2196F3;
    color: white;
    padding: 15px;
    font-size: 18px;
  }

  .card-image {
    height: 200px; /* Fixed height for card images */
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      height: 100%; /* Maintain aspect ratio and fill the container */
      object-fit: cover; /* Cover the entire container without stretching */
      display: block;
    }

    .share-overlay{
      position: absolute;
      top: 50%; /* Adjust the vertical position */
      right: 0;
      transform: translateY(-50%);
      background-color: rgba(0, 0, 0, 0.3); /* Adjust the overlay color and opacity */
      padding: 10px; /* Adjust padding */
      display: flex;
      flex-direction: column; /* Stack items vertically */
      align-items: flex-end;
      border-top-left-radius : 8px;
      border-bottom-left-radius : 8px;
    }
    
    .share-buttons {
      display: flex;
      flex-direction: column; /* Stack icons vertically */
      align-items: flex-end; /* Align icons to the right */
      gap: 10px;
    }
  }

  
  .card-content {
    padding: 15px 10px 15px 10px;
    display: flex;
    flex-direction: column;

    .category {
      margin-bottom: 8px;
      font-weight: bold;
    }

    .title {
      font-size: 15px;
      height: 45px;
      margin: 10px 2px;
      flex-grow: 1; /* Allow the title to grow vertically */
      color: #fb7f3d;
      text-decoration: none;
      max-height: 45px; /* Set a maximum height for the title */
    }

    .statistics {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: auto;
      font-size: 14px;
      padding-top: 10px;

      .stat-item {
        display: flex;
        align-items: center;

        .icon {
          margin-right: 4px;
        }
      }

      /* Style for the "Peers" section */
      .peerSection {
        width: 33.33%; /* Set the width to 33.33% for equal spacing with other sections */
        padding: 10px; /* Add some padding for spacing */
        border: 1px solid #ccc; /* Add a border for visual separation */
        border-radius: 4px; /* Add border radius for rounded corners */
        text-align: center; /* Center-align content */
        font-size: 14px; /* Adjust font size */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .icon {
          margin-right: 4px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    max-width: 100%; /* Full width for smaller screens */
  }
}
