@import '../../../../assets/main';
.learningText {
  font-size: 14px;
  color: #00a89a;
}
.infoIcons {
  font-size: 16px !important;
  margin-left: 4px;
  color: #00a89a;
}

.paraStyle {
  font-size: 12px;
  color: $slateGrey;
}


.RemarksText {
    font-size: 12px;
    color: #757f88;   
}
