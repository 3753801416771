@import '../../../../assets/main';

.LearningProgressContainer {
    width: 30% !important;
}

.LessonName {
    color : $persianGreen !important;
}

.scaleIcon {
    width: 36px !important;
    height : 36px !important
}


.StepperIcon {
    width:36px !important;
    height: 36px !important;
}

.textColor{
    color:$persianGreen
}

.descriptionText{
    word-break: break-all;
    font-size: 10px;
}