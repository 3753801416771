@import '../../assets/main';

.defaultSelect {
  svg {
    color: $persianGreen !important;
  }
}

.defaultSelectInput {
  color: $persianGreen !important;
  font-weight: 500;
}

.textTransform {
  text-transform: capitalize !important;
}

.filterIcon {
  transform: rotate(0deg) !important;
}

.backdrop {
  z-index: 1301;
}
