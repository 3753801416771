@import '../../../../../assets/main';
.iconColor{

  color: #00a89a;
}

.LineGraphContainer { 
  width: 90%;
  margin-left:10px;  
  
}