@import '../../../assets/vars';

.LinearProgressContainer {
    padding-bottom: 2px;
    border-bottom: 2px solid $PattensBlue;
}

.LinearProgressGraph {
    height : 8px !important;
    background-color: transparent !important;
    border-radius: 100px !important;
    
}
.LinearProgressGraphBar{
    border-radius: 100px !important;
}

.StatsText {
    color : $slateGrey;
}

.UsageBarStyling {
    background-color: $BlueViolet !important;
}

.UsersStyling {
    background-color: $burntOrange !important;
}

.NaudgeIncreaseStyling {
    background-color: $persianGreen !important;
}

.ActionableInsights {
    font-family: sans-serif;
    position: relative;
    font-style: normal;
    font-weight: 600;
    // width:404px;
    height: 108px;
    top: 40px;
    left: 52px;
  }
  
  .ActionableInsightsContainer {
    background-color: $white;
    box-shadow: 0px 2.39655px 23.9655px rgba(0, 0, 0, 0.12);
    padding: 30px;
    margin: 50px;
    border-radius: 7.18965px;
  }
  
  .buttonAlignment {
    right: 100px;
  }
