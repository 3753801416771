@import '../../../assets/main';

.popperClass {
  width: 35% !important;
  left: -3.5rem !important;
  z-index: 49 !important;
}

.clickAway {
  width: 100%;
  background-color: #ffffff;
  border-radius: 0.375rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.notification {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.notificationText {
  font-size: 1.875rem /* 30px */;
  line-height: 2.25rem /* 36px */;
  font-weight: 600;
  color: grey;
}

.notificationContainer {
  overflow-y: auto;
  overscroll-behavior: contain;
  height: 70vh;
}

.noNewnotification {
  text-align: center;
  margin-top: 11rem;
  margin-bottom: 11rem;
  color: #696969;
}

.notificationData {
  display: flex;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
}

.unreadNotifications {
  background-color: $WhiteSmoke;
}

.notificationDataSpan {
  display: flex;
  align-items: center;
  width: 90%;
}

.notificationDataAvatar {
  width: 3rem !important;
  height: 3rem !important;
}
.notificationAvatarInitials {
  background-color: rgba(0, 168, 154, 0.12) !important;
  color: $persianGreen !important;
}
.notificationDataName {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 18px;
}

.notificationDataMsg {
  display: flex;
  flex-direction: column;
  font-size: 11px;
}

.leaveModalContainer {
  outline: none;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);

  .BoxContainer {
    background: $white;
  }

  .leaveMailContainer {
    padding: 20px 10px;
  }

  .cancelButton {
    color: $PersianGreen !important;
    text-transform: capitalize !important;
  }

  .continueBtn {
    color: $white;
    background-color: $PersianGreen !important;
    margin-left: 8px !important;
    text-transform: capitalize !important;
  }

  .btnDivContainer {
    margin-top: 20px;
  }
}

@media (max-width: 320px) {
  .notificationData {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 479px) {
  .notificationDataBadge {
    align-items: center;
    flex-direction: row;
    font-size: 0.625rem;
  }
  .notificationDataMsg {
    align-items: center;
    flex-direction: row;
  }
}

@media (max-width: 1200px) {
  .notificationDataBadge {
    font-size: 1.125rem;
  }
  .notificationDataMsg {
    font-size: 0.75rem;
  }
}

@include mediaQuery(
  (
    header: (
      height: 60,
    ),
    headerDivider: (
      height: 26,
    ),
  )
);
