@import '../../../assets/main';
.EmployeeDataContainer {
  background-color: $white;
  box-shadow: 0px 2.39655px 23.9655px rgba(0, 0, 0, 0.12);
  padding: 30px;
  border-radius: 10px;
}
.columnsStyling {
  border: none !important;
  .TableData {
    color: $eclipse;
  }
}

.PanalHeading {
  font-size: 22px;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
}
.PanalSubHeading {
    color : $santasGrey;
}