@import "../../assets/main";
@import "../../pages/signUp/signUp.module.scss";
@import "../../assets/styleguide.scss";



.signUPNav {
  width: 100%;
  background: $brigthGray;
  height: 8vh;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.147318);
}



.signInBtn {
  width: 145px;
  height: 34px;
  border-color: $black;
  border-radius: 20px !important;
}

.signUpHeading {
  font-family: $font_Poppins_sans_serif;
}

.pageLayoutDesc {
  opacity: 0.6;
  margin-bottom: 30px;
}

.signupContinueButton {
  display: flex !important;
  align-items: center !important;
  margin-top: 8vh !important;
  font-size: $font_20 !important;
}

.signupContinueButtonLeft {
  display: flex !important;
  margin-top: 20px !important;
  align-items: center !important;
  font-size: $font_20 !important;
}


.signUpSkipBtns {
  font-size: $font_18 !important;
  color: $Firefly !important;
  margin-top: 3rem !important;
  bottom: 0% !important;
}

.signUpBtns {
  font-size: $font_20 !important;
  height: 60px;
}

.purchaseLicenseBtn {
  height: 80px;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.otpBox {
  width: calc(32% - 60px) !important;
  height: calc(32% - 60px) !important;
  min-height: 65px;
  max-height: 65px;
  max-width: 65px;
  min-width: 65px;
  text-align: center;
  background-color: transparent;
  border: 1px solid $chineseSilver;
}
.otpBox:focus {
  outline: none;
  border-color: $Tradewind;
}

.LeftPanalHeading {
  color: $White;
  font-family: $font_Poppins_sans_serif;
}

.LeftPanalDesc {
  color: $White;
  font-family: $font_Poppins_sans_serif;
}

.signUpFieldsContainer {
  .signUpFields {
    border-radius: 4px;
    background-color: #c6d3d2 !important;
    position: relative;
    border: 1px solid #c6d3d2 !important;
    outline: none !important;
    color: #000000;
    font-family: $font_Poppins_sans_serif !important;
  }
  fieldset {
    display: none;
  }
}

.centerImage {
  max-height: 30vh !important;
}

.accessBtn {
  font-size: $font_20;
  width: 263px;
  height: 50px;
  bottom: 75px;
  background-color: $Swamp !important;
}

.ahuraLogoStyling {
  position: relative !important;
}

.CorporateImage {
  position: absolute;
  bottom: 0%;
  left: 0%;
  width: 100%;
  margin-top: 80px;
}

.backgroundColorBtn {
  background: linear-gradient(
    168.35deg,
    $IrisBlue 139.58%,
    $Swamp 592.83%
  ) !important;
}

.buttonMargin {
  margin-top: 8vh !important;
}

.backgroundBtnDisable {
  background: linear-gradient(
    168.35deg,
    $Firefly 139.58%,
    $Swamp 592.83%
  ) !important;
  color: $White !important;
  p {
    opacity: 0.3;
  }
}

.Icons {
  position: relative;
  width: 24px;
  height: 24px;
}

.Interestbtn {
  position: relative;
  width: 187px;
  height: 59px;
  border-radius: 5px;
  background: $Firefly;
  color: $White;
  cursor: pointer;
}

.InterestbtnActive {
  background: $IrisBlue !important;
}

.ExtensionColor{
  color: $IrisBlue !important;
  border: 1px solid $IrisBlue !important;
}

.FaceCalibrationProgressText {

  font-family: $font_Poppins_sans_serif;
  font-weight: bold;
  font-size: 10px;
  color : $santasGrey;
}

.ParaLeft {
  margin-left: 12%;
}

.ParaRight {
  margin-right: 13%;
}

.LinearProgress {
  height: 6px !important;
  border-radius: 20px;
}

.LicenseClientDrop {
  font-size: $font_18;
  border-radius: 5px !important;
  
  ul {
    padding: 0 !important;
    li{
      font-weight: lighter;
      border-bottom : 1px solid $DarkGray;    
    }  
      
  }
}

.EducationLevelDrop{
  
  font-size: $font_18;
  top : 330px !important;
  border-radius: 5px !important;
  
  ul {
    padding: 0 !important;
    li{
      font-weight: lighter;
      border-bottom : 1px solid $DarkGray;    
    }  
      
  }
  
}

.SelectedItem{
  color : $IrisBlue !important;
}

.purchaseDividertext{
  color : $Firefly !important;
}



//media query
@include mediaQuery(
  (
    signInBtn: (
     height: 34,
    ),
    Interestbtn :(
      height:59
    ),
    purchaseLicenseBtn:(
      height:80,
    ),
  )
);

.mailLink {
  text-decoration: none;
  color : $persianGreen;
  font-size: 24px;
}

.groupCorpInfo {
  text-decoration: none;
  color : $persianGreen;
}

.extensionImages {
  height : 310px !important;
}

.rightpanelMainbox{
  display: table;
  padding: 10px;
  float: left;
}

.rightpanelSubbox{
  float: left;
  padding: 5px;
  position: relative;
}
.rightpanelSubbox img{
  height: 200px;
  width: 300px;
  opacity: 0.5;
}

.rightpanelSubbox button{
  position: absolute;
  bottom: 20px;
  right: 30px;
}
.crossIcon
{
  width: 25px;
  height: 25px;
}