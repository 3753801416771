@import '../../assets/main';

.pageContainer {
  padding: 24px 50px 59px 97px;
  z-index: -1;
  overflow-y: auto;
  height: calc(100vh - 59px);
}

.adminPageContainer {
  margin-top: 60px;
}