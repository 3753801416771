@import '../../../../../assets/main';
.listItemsIcon {
  margin-left: 2.5rem !important;
  min-width: 46px !important;
  color: $black !important;
}
.LensIcon {
  font-size: 34px !important;
}

.menuItemName {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  font-weight: 400 !important;
  color: $martinique !important;
}
.categoryCount {
  justify-content: center !important;
  margin-right: 0.5rem !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  font-weight: 700 !important;
  text-align: center !important;
  height: 1.5rem !important;
  border-radius: 0.5rem !important;
  min-width: 43px !important;
  display: flex !important;
  align-items: center !important;
  background-color: $nobel_1 !important;
  color: $white !important;
}
