  .overlay {
    position: fixed; /* Fixed position to overlay on top of the page */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* Make sure it overlays everything */
  }
  
  .progressBarContainer {
    text-align: center;
    color: white; /* Set text color to white for visibility */
  }
  
  .loadingMessage {
    margin-top: 10px;
    font-size: 1.2rem;
  }
  