@import '../../assets/main';

.profilePage {
  z-index: -1;
}

.profileDetails {
  padding: 35px 60px 70px 50px;
}

.profileStatus {
  color: $santasGrey;
}

.profileProgress {
  height: 10px;
  border-radius: 25px;
}
