@import '../../../assets/main';

.cardContainer {
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding-bottom: 10px;
}

.CardContent {
    
    width : 275px;
}

.Action {
    cursor: pointer;
}

.infoIcon{
    font-size: 14px !important;
    margin-top: 3.3px;
    color: $PersianGreen;
      
}

.ActionsContainer {
    border : none !important;
    display:flex;
    justify-content: right;
    margin-top: 2rem;
}