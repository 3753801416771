.networkWrapper {
  margin-top: 23px !important;
  margin-bottom: 9px !important;
}
.menuItem {
  margin-left: 2.5rem !important;
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  line-height: 1.25rem !important;
  text-transform: capitalize !important;
}
