@import '../../../assets/main';

.courseBack {
  color: $persianGreen !important;
  margin-top: 6vh;
}

.StartCourseBtnDiv{
  text-align: center;
  width: 55%;
  .StartCourseBtn {
    float:left;
    width: 100%;
    margin: 5px !important;
  }
  .StartCourseBtnTextBelow{
    font-size: 12px;
  }
  .StartCourseBtnTextBelowDisabled{
    font-size: 12px;
    color:grey !important;
  }
}

.TakeQuizBtnDiv{
  text-align: center;
  width: 45%;
  padding: 10px;
  .TakeQuizBtn {
    float:left;
    width: 100%;
    margin: 5px !important;
  }
  .TakeQuizBtnTextBelow{
    font-size: 12px;
  }
  .TakeQuizBtnTextBelowDisabled{
    font-size: 12px;
    color:grey !important;
  }
}

.contantHeading {
  color: $persianGreen !important;
  margin-top: 6vh;
}

.CreatorInfoContainer {
  border-top: 2px solid $Gainsboro !important;
  border-bottom: 2px solid $Gainsboro !important;
}

.SwitchBox{
  width: 163px;
  height: 51px;
  background-color: $veryLightGrey !important;
  border-radius: 5px;
}

.SwitchFull{
  width: 73px;
  height: 43px;
  background-color: $persianGreen !important;
  border-radius: 5px;
  box-shadow: none !important;
  margin-right: 4px !important;
}

.SwitchLive{
  width: 73px;
  height: 43px;
  background-color: $veryLightGrey !important;
  border-radius: 5px;
  box-shadow: none !important;
  margin-left: 4px !important;
  color: $black !important;
}

.CourseDescription{
  font-size: 18px;
}

.IconCircle{
  background-color: rgba($PersianGreen, .1);
  width: 36px;
  height: 38px;
  border-radius: 100%;
  color: $PersianGreen;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ChapterSidePanel{
  height:1200px;
  position: relative;
}

.ChapterSidePanelInner{
  height: calc(1200px - 75px); 
  overflow-Y:scroll;
}

.ChapterSidePanelFooter{
  background-color: #F3F3F4;
  height: 75px; 
  bottom: 0;
  left: 0;
  width: 100%;

}

.CreatorIconLogo{
  width: 90px;
  height: 90px;
  border-radius: 100%;
}
.social-button{
$btn-color: #979695;
$btn-hover-color: #5e5e5e;
border-color: $btn-color;
color: $btn-color;
transition: 0.5s;
&:hover {
  transform: translateY(-0.25em);
  box-shadow: 0 0 0.5em 0em $btn-hover-color;
  border-color: $btn-hover-color;
  color: $btn-hover-color;
  border-radius: 50px;
  transition: 0.5s all;
}
}