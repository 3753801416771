.creatorActionsIcon {
    font-size: 36px !important;
    height: 36px !important;
    width : 36px !important;
}

.UserActionContainer {
    margin-left : auto !important;
    margin-right: 3vw ;
}
