@import '../../../assets/main';

.mainContainer {
  min-height: 1000px;
  // min-h-[1000px]
}

.tableContainer {
  overflow-x: auto;
}

.font16{
  font-size: $font_16;
}


.columnsStyling {
  width: auto;
  border: none !important;
  // .TableData {
  //   color: $eclipse;
  //   padding-top: 12px;
  // }
}

.button {
  width: 180px !important;
  height: 40px !important;
  border-radius: 15px !important;
  justify-content: space-between !important;
  align-items: flex-start !important;

  .text {
    text-transform: none !important;
    font-weight: 500 !important;
  }
}

.dragIcon {
  font-size: $font_18 !important;
  margin-right: 10px !important;
}

.textLeft {
  margin-left: 10px;
}

.tableImgBox {
  width: 60px;
  height: 60px;
  background: $gainsboro;
  border-radius: 5px;
}


.TextColorC4 {
  color: $charcoal;
}

.tableCell8IconCircle {
  width: 50px;
  height: 50px;
  background: $gainsboro;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuDropdown{
  width: 180px !important;
  background-color: $persianGreen !important;
  color: $white !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 1rem !important;  
  border-bottom-left-radius: 1rem !important; 
}

.bottomBorderNone{
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.bottomBorder{
  border-bottom-right-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important; 
}

.cursor-pointer{
  cursor: pointer;
}