@import '../../assets/main';

.learningPlans {
  background: $white;
  padding: 35px 50px;
}

.search {
  border: 1px solid rgba($color: $persianGreen, $alpha: 0.36);
}
