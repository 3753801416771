@import '../../assets/main';

// Headings
.barMargin {
  margin-left: 80px;
  // height: 380px !important;
}

.sessionChartLabel {
  background-color: $java;
  font-size: 10px;
  border-radius: 8px;
  padding-left: 2px;
}
.heading0 {
  font-size: 60px;
}

.heading1 {
  font-size: 50px;
}

.heading2 {
  font-size: 36px;
}

.heading3 {
  font-size: 24px;
}

.heading4 {
  font-size: 22px;
}

.heading5 {
  font-size: 20px;
}

.heading6 {
  font-size: 18px;
}

.heading30 {
  font-size: 30px;
}

.heading32 {
  font-size: 32px;
}

.heading36 {
  font-size: 36px;
}

// Texts

.text0 {
  font-size: 16px;
}

.text1 {
  font-size: 14px;
}

.text2 {
  font-size: 12px;
}

.text3 {
  font-size: 10px;
}

.emotionIconSize {
  font-size: 10px !important;
}
.text4 {
  font-size: 8px;
}

.text5 {
  font-size: 6px;
}

.text18 {
  font-size: 18px;
}

.text22 {
  font-size: 22px;
}

.text24 {
  font-size: 24px;
}

// Welcome Section

.welcomeSection {
  height: 92px;
  background-color: $white;
  padding: 0px 30px;
}

.lastSessionTopic {
  max-width: 175px;
}

.resumeSessionBtn {
  background: rgba(0, 168, 154, 0.12) !important;
  box-shadow: none !important;
  color: $persianGreen !important;
}

.StopSessionBtn {
  cursor: pointer;
  background: $RadicalRed !important;
  color: $White !important;
  box-shadow: none !important;
}

.liveChip {
  background: $carmine;
  border-radius: 24px;
  color: white;
  padding: 3px 5px;
}

// Ability with percentage

.ability {
  font-weight: 500;
}

.percentage {
  color: $persianGreen;
}

.HouseCardDesign {
  border-top-left-radius: 30px;
  border: none;
  border-top-right-radius: 30px;
}

.HouseCardBody {
  position: relative;
  z-index: -1;
  border: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.HouseCardBodynew {
  border: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

// Custom Table

.tableContainer {
  max-height: 950px;
}

.tableContainer::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

.tableContainer::-webkit-scrollbar-track {
  background: $ghostWhite;
}

.tableContainer::-webkit-scrollbar-thumb {
  background: $persianGreen;
  border-radius: 6px;
}

.tableHeading {
  font-family: Inter !important;
  background: $lightCyan !important;
  // min-width: 190px;
}

.tableSortIcon {
  fill: $black !important;
  stroke: $black;
  stroke-width: 2.7px;
  transform: rotate(90deg);
  font-size: 15px !important;
}

.tablePagination {
  p {
    margin-bottom: 0px !important;
  }
}

.tableWidth {
  width: 100%;
}

// Dotted Input Upload

.dottedInput {
  border: 0.5px dashed $persianGreen;
  border-radius: 5px;
}

.dottedInputText {
  color: $eclipse;
  font-weight: 500;
}

.accounts {
  color: $PersianGreen;
  font-size: 20px;
}

.linearProgress {
  height: 2px !important;
  color: $PersianGreen;
}

.card1 {
  // margin-top: 12px;
  border: 2px dashed $TurquoiseBlue;
  box-shadow: none;
}

.iconText {
  margin-top: 50%;
}

.addIcon {
  color: $TurquoiseBlue;
  font-size: 40px !important;
  cursor: pointer;
}

.card2 {
  // margin-top: 12px;
  border-bottom: 4px solid $PersianGreen;
  // margin-left: 12px;
}

.moreHorizonIcon {
  margin-left: 89%;
  margin-top: -24px;
  font-size: 34px !important;
  color: $PersianGreen;
}

.imageStyle {
  height: 80px !important;
  width: 80px !important;
  border-radius: 50%;
}

.secoundImg {
  position: relative;
  left: 44%;
  bottom: 25px;
}

.personalProfile {
  text-align: center;
  margin: 0;
  font-size: 14px;
  color: $RobinsEggBlue;
}

.para {
  text-align: center;
  margin: 0;
  font-size: 14px;
}

.paraCorporate {
  margin: 0;
  text-align: center;
  font-size: 13px;
}

.mainDiv {
  background-color: white;
  height: 100vh;
}

.colorAddAccount {
  color: $RobinsEggBlue;
}

// Modal Box

.modalBox {
  outline: none;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
}

.modalTopBorder {
  height: 10px;
  background: linear-gradient(
    131.62deg,
    $robinsEggBlue -6.12%,
    $persianGreen 82.96%
  );
}

// Profile Picture

.imageDiv {
  border-color: $IrisBlue !important;
}

// Session Details

$barColorVariants: (
  goodBehaviorBar: $persianGreen,
  neutralBehaviorBar: $santasGrey,
  badBehaviorBar: $burntOrange,
);

@mixin sessionBarProps($selector, $bgcolor) {
  .#{$selector} {
    background: $bgcolor;
  }
}

.sessionDetails {
  height: calc(100% - 42px);
  width: calc(100% - 200px);
  outline: none;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
  .firstBox {
    padding-left: 34px;
    background: $persianGreen;
  }
  .profileImage {
    height: 197px;
    width: 197px;
  }
  .visitedPage {
    color: $Firefly;
  }
}

.summaryDetails {
  height: calc(100% - 42px);
  width: calc(100% - 200px);
  // flex-shrink: 0;
  .firstBox1 {
    background: var(
      --teal,
      linear-gradient(147deg, #008080 0%, rgba(0, 128, 128, 0.6) 100%)
    );
    .firstBox2 {
      width: 90%;
      font-size: 16px;
      background: #13585c;
      margin-right: 2rem;

      .doughnutData {
        font-size: 9px !important;
        font-weight: 400 !important;
        padding-left: 2px;
      }
    }
  }
}

.emotionHeading {
  font-size: 14px;
  font-weight: 500;
  color: #c2c2c2;
  text-align: center;
  margin-top: 8px;
}
.emotions {
  font-size: 16px;
  font-weight: 500;
  color: white;
  padding-top: 5px;
}
.showButton {
  background: #fff;
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.1);
  height: 66px;
}

.buttonText {
  color: #000 !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 34px !important; /* 141.667% */
}

.firstBox3 {
  background: var(--White, #fff);
  box-shadow: 0px 0px 14.09429px 0px rgba(0, 0, 0, 0.08);
}

.textHeading {
  color: var(--new-colors-text-black, #2c2e3e) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important; /* 125% */
  margin-bottom: 15px !important;
}
.barDiv {
  height: '360px' !important;
  width: '100%';
  overflow-y: 'auto' !important;
}
.chartLabelsMain {
  left: '0';
  height: '360px' !important;
}
.textContent {
  color: #666669 !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.contentHeading {
  color: #2c2e3e !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important; /* 142.857% */
}

.gaugeData {
  font-size: 10px;
  font-weight: 400;
}

.boxMargin {
  margin-top: 48px;
}

.secondBox {
  background: var(
    --teal,
    linear-gradient(147deg, #008080 0%, rgba(0, 128, 128, 0.6) 100%)
  );
  padding: 10px;
}

.secondBox span {
  color: $lightYellow;
}

.sessionDetailWhite {
  height: calc(70% - 100px);
  width: calc(100% - 200px);
  outline: none;
  color: $persianGreen;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
  .firstBox {
    background: $ghostWhite;
    color: $persianGreen;
  }
  .headingColor {
    color: $persianGreen;
  }
  .buttonColor {
    background: $persianGreen;
    color: $ghostWhite;
  }
  .profileImage {
    height: 197px;
    width: 197px;
  }
  .visitedPage {
    color: $persianGreen;
  }
}

.tipsContent {
  color: rgba(102, 102, 105, 1);
  text-align: justify;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  // padding-top: 3rem;
}

.urlText {
  color: #ffa828;
  font-family: Jost;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.sessionTime {
  color: $echoBlue;
}

.sessionBar {
  height: calc(50% - 80px);
}

.playerWrapper {
  position: relative;
  // padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */;
}

.reactPlayer {
  position: absolute;
  top: 0;
  left: 0;
}

.toolbarclass {
  background: rgba($color: $persianGreen, $alpha: 0.3) !important;
}

.autoStoppedCls {
  background-color: $putty;
  margin-top: 5rem;
  font-size: 20px;
  width: 90%;
  border-radius: 10px;
}

.editorClass {
  height: 160px !important;
  overflow: hidden;
  border: 1px solid #dbe3eb;
}

// Nudges

.nudgesBox {
  width: 12%;
  background-color: #00a89a;
  color: white;
  border: 2px solid $Silver !important;
}
.nudgesHeading {
  border-bottom: 2px solid #dbe3eb;
}

.sessionChartTime {
  color: $nobel;
}

@each $key, $value in $barColorVariants {
  @include sessionBarProps($key, $value);
}

// Media Query

@include mediaQuery(
  (
    welcomeSection: (
      height: 92,
    ),
    TableContainer: (
      max-height: 650,
    ),
    modalTopBorder: (
      height: 10,
    ),
    heading0: (
      font-size: 60,
    ),
    heading1: (
      font-size: 50,
    ),
    heading2: (
      font-size: 36,
    ),
    heading3: (
      font-size: 24,
    ),
    heading4: (
      font-size: 22,
    ),
    heading5: (
      font-size: 20,
    ),
    heading6: (
      font-size: 18,
    ),
    heading30: (
      font-size: 30,
    ),
    heading32: (
      font-size: 32,
    ),
    heading36: (
      font-size: 36,
    ),
    text0: (
      font-size: 16,
    ),
    text1: (
      font-size: 14,
    ),
    text2: (
      font-size: 12,
    ),
    text3: (
      font-size: 10,
    ),
    text4: (
      font-size: 8,
    ),
    text5: (
      font-size: 6,
    ),
    text22: (
      font-size: 22,
    ),
    text24: (
      font-size: 24,
    ),
  )
);
