@import '../../assets/main';

@mixin containerProps($selector) {
  .#{$selector} {
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.12);
    @content;
  }
}

// General Information

@include containerProps(generalInfo) {
  padding-bottom: 65px;
  color: rgba(var(--color), 0.2);
}

.imageDimensions {
  height: 180px !important;
  width: 180px !important;
  border-color: $persianGreen !important;
}
.imageDimensionsReadonly {
  border-color: rgba(0, 0, 0, 0.26) !important;
  height: 180px !important;
  width: 180px !important;
}

.errorIcon {
  color: $carmine;
}

.profileInputField {
  color: $persianGreen !important;
  font-weight: 600 !important;
}
.profileInputFieldReadOnly {
  color: rgba(0, 0, 0, 0.26) !important;
}
.profileInputField[readonly] {
  color: rgba(0, 0, 0, 0.26) !important;
}

//LearningPreferences

@mixin lessonIconProps($selector, $bgColor) {
  .#{$selector} {
    background-color: $bgColor;
    @content;
  }
}

$lessonIconContent: (
  Article: $persianGreen,
  Audio: $Cerulean,
  OnlineCourses: $Salem,
  Podcast: $persianGreen,
  Video: $persianGreen,
);

@include containerProps(LearningPreferencesContainer);

.sliderText {
  color: $persianGreen;
  font-weight: 600;
}

.airbnbSlider {
  height: 5px;
  padding: 13px 0;
}

@each $key, $value in $lessonIconContent {
  @include lessonIconProps($key, $value);
}

// Badges

@mixin badgesHexagon($selector, $angle, $color-stop...) {
  .#{$selector} {
    clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    height: 80px;
    width: 80px;
    margin: 20px 25px 20px 28px;
    background: linear-gradient(
      #{$angle}deg,
      nth($color-stop, 1) 0%,
      nth($color-stop, 2) 100%
    ) !important;
    @content;
  }
}

@include containerProps(badges) {
  padding: 23px 56px 37px 50px;
  min-height: 690px;

  .containerDiv {
    margin: 0 30px 15px 37px;
    box-shadow: 0px 2px 18px 0px rgba(130, 130, 130, 0.25);

    .secondDiv {
      max-width: 124px;
    }

    .thirdDiv {
      max-width: 165px;
      padding-left: 21px;
    }
  }

  @include badgesHexagon(hexagon1, 180, $burntOrange, $mandysPink);
  @include badgesHexagon(hexagon2, 90, $brightTurquoise, $atlantis);
  @include badgesHexagon(hexagon3, 180, $robinsEggBlue, $persianGreen);

  .vertical {
    max-width: 55px;
    border-left: 1px solid $veryLightGrey;
    margin: 40px 0;
  }

  .moreHorizSharpIcon {
    margin: 49px 42px 46px 0;
  }
}

// Targets

@mixin targetCapabilitiesProps($selector, $bgColor) {
  .#{$selector} {
    height: 30px;
    width: 30px;
    background: $bgColor;
    @content;
  }
}

@include containerProps(targets);

@include targetCapabilitiesProps(capability1, $persianGreen);
@include targetCapabilitiesProps(capability2, $salem);
@include targetCapabilitiesProps(capability3, $Cerulean);

.dropdownBox {
  max-height: 10rem !important;
}

.assignTargetBtn {
  max-height: 56px;
  margin-top: 66px !important;
  border: 2px solid $persianGreen !important;
}

.targetsWithDateCon {
  max-height: 400px;
  overflow: auto;
}

.selectedDate {
  background: rgba($color: $persianGreen, $alpha: 0.3);
  border-radius: 8px;
}

.calanderBorder {
  border: 1px solid $Silver;
  border-radius: 8px;
  padding: 4px;
}

.addScheduleModal {
  height: auto;
  width: 40%;
  background: $white;
  top: 50%;
  left: 50%;
  position: absolute;
  border-top: 10px solid $persianGreen;
  border-radius: 8px;
  transform: translate(-50%, -50%);
}
//media query

@include mediaQuery(
  (
    badges: (
      min-height: 690,
    ),
    airbnbSlider: (
      height: 5,
    ),
    assignTargetBtn: (
      max-height: 56,
    ),
  )
);

.EditStyle {
  margin-top: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: $persianGreen;
}

.CalibrationInput {
  width: 142px;
  height: 162px;
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.RecalibrateButton {
  width: 102px;
  height: 35px;
  padding: 10px 18px;
  border-radius: 5px;
  font-weight: 600 !important;
  font-size: 12px !important;
}
.RecalibrateButtonWrapper {
  display: flex;
  align-items: center;
  margin-left: 2rem;
  justify-content: center;
}
.scheduleContainer {
  background-color: '#f2fafa';
  color: '#00A89A';
  border-radius: '5px';
  min-height: '108px';
}
.modalBox {
  background: #ffffff;
  box-shadow: 0px 0px 9.38082px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}
