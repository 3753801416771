@import "../../assets/main";

.tcSubHeading{
    font-weight: bold !important;
    font-style: italic;
  }
  
  .noLinkUnderline {
    text-decoration: none;
  }

  .terms{
      height: 100vh;
      overflow: auto;
  }