@import '../../assets/main';

.groupSection {
  background: $azure;
  th {
    // background: $mabel !important;
    // color: $lightSeaGreen !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 24.5px !important;
    letter-spacing: 0.4px !important;
    text-align: center !important;
    // border-right: 2px solid white;
  }

  .topSection {
    padding: 16px 40px;
  }

  .heading {
    color: $persianGreen;
    // font-family: Inter;
    font-style: normal;
    line-height: normal;
  }

  // .groupTableRow td {
  //   color: $eclipse !important;
  //   background: $tranquil !important;
  //   font-size: 14px;
  //   font-weight: normal;
  //   border-bottom: 0 !important;
  //   border-right: 2px solid white;
  // }
  .groupTableRow td {
    color: $eclipse !important;
    font-size: 14px;
    font-weight: normal;
    background: white;
  }

  // .groupTableContainer {
  //   padding: 0 65px;
  // }

  .columnStyling {
    // border: none !important;
    .TableData {
      color: $eclipse;
    }
  }

  .buttonText {
    font-weight: 600;
  }

  tr > :last-child {
    border-right: none;
  }
}

.fieldBox {
  width: 30%;
}

.alertOrganisation {
  color: red;
  font-size: 14px;
  margin-top: 2px;
}

.searchGroupBox {
  width: 300px;
}

.searchInvitingMembers {
  width: 500px;
}

.MemberContainer {
  height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.inviteMembers {
  display: flex;
  align-items: center;
  padding: 12px 12px 12px 0;
  width: 90%;

  .inviteMembersSpan {
    display: flex;
    align-items: center;
    width: 83%;
  }

  .inviteMembersAvatar {
    width: 3rem !important;
    height: 3rem !important;
  }

  .inviteMembersName {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    font-size: 0.75rem;
  }

  .inviteMembersMsg {
    display: flex;
    flex-direction: column;
    font-size: 0.5rem;
  }
}

.backToGroupCreation {
  cursor: pointer;
  width: 20px;
  .arrowKey {
    fill: $persianGreen;
  }
}

.filterClass {
  padding: 0;
}

.inviteAllBtn {
  width: 114px;
  height: 38px;
  font-family: 'Inter' !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.ModalContainer {
  outline: none;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);

  .BoxContainer {
    background: $white;
  }

  .imageDimensions {
    border-color: $persianGreen !important;
    height: 90px !important;
    width: 90px !important;
  }
  .addEditButton {
    border-radius: 40px;
    font-size: 10px;
    padding: 4px;
  }
  .autoCompleteIcon {
    right: 2%;
  }

  .closeIcon {
    top: -28px;
    right: -8px;
  }
}

.leaveModalContainer {
  outline: none;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);

  .BoxContainer {
    background: $white;
  }

  .leaveMailContainer {
    padding: 20px 10px;
  }

  .cancelButton {
    color: $PersianGreen !important;
    text-transform: capitalize !important;
  }

  .continueBtn {
    color: $white;
    background-color: $PersianGreen !important;
    margin-left: 8px !important;
    text-transform: capitalize !important;
  }

  .btnDivContainer {
    margin-top: 20px;
  }
}

.noDataFound {
  text-align: center;
  margin-top: 11rem;
  margin-bottom: 11rem;
  color: #696969;
}

.AutoSearch {
  fieldset {
    top: 0;
  }
  legend {
    display: none;
  }
  height: calc(100% - 114px) !important;
}

@media (max-width: 1200px) {
  .ModalContainer {
    .inviteMembersMsg {
      font-size: 0.75rem;
    }
  }
  .textField {
    width: 70% !important;
  }
}

@media (min-width: 1300px) and (max-width: 1439px) {
  .textField {
    width: 70% !important;
  }
}

@media (min-width: 1920px) {
  .textField {
    margin-left: 2rem !important;
  }
  .searchBox {
    margin-left: 0 !important;
  }
  .inviteMemberBox {
    margin-left: 1.5rem;
  }
}
