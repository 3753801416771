@import "../../assets/main";


.signupContainer {
  overflow-y: auto;
  height: 92vh;
  width: 100%;
}
.rightPanel {
  padding: 50px;
  // height: 100%;
  background-color: $brigthGray;
}

.leftPanel {
  background: rgba(0, 128, 0, 0.6) url("../../assets/images/background.png")
    no-repeat;
  background-size: 100% 100%;
  // height: 100%;
  padding: 120px;
}

.SignupFooter {
  width: 100%;
  position: absolute;
  bottom: 0%;
  right: 0;
}

.ArrowBackCustomStyle {
  background: #3a4545;
  height: 33px !important;
  width: 33px !important;
  padding: 5px;
  opacity: 0.5;
  color: $White;
}

.loading {
  background-color: $BlueWhale;
  color: $White;
  padding-top: 20px;
}

.leftPanelIconDiv{
  display: flex;
  position: absolute;
 bottom: 3%;
 right : 130%;
}

.activeIcon{
  color: $WhiteSmoke;
}

.inActiveIcon{
  color: $DimGray;
}