.carousel-control-prev {
    left: -74px;
  }
  
  .carousel-control-next {
    right: -74px;
  } 

.carousel .carousel-control-next-icon {
  /* height: 100px;
  width: 100px; */
  /* outline: black; */
  /* background-size: 100%, 100%;
  border-radius: 10%;
  border: 1px solid black;
  background-color: white;
  color:White; */
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16"> <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z"/> </svg>');
}

.carousel .carousel-control-prev-icon {
    /* height: 100px;
    width: 100px; */
    /* outline: black; */
    /* background-size: 100%, 100%;
    border-radius: 10%;
    border: 1px solid black;
    background-color: white;
    color:White; */
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-square-fill" viewBox="0 0 16 16"> <path d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1z"/> </svg>');
  }

.carousel-control-next-icon:after
{
 background-color: black;

}

.carousel-control-prev-icon:after {
  background-color: black;
}

