@import '../../../../assets/main';
.faceCalibrationContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.faceDataWrapper {
  max-width: 137.99px;
  max-height: 138px;
}
.RecalibrateButton {
  width: 102px;
  height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  border-radius: 5px;
  font-weight: 600 !important;
  font-size: 12px !important;
}

.CalibrationData {
  text-align: center;
  height: 156px;
  font-weight: 600 !important;
}
.calibrationText {
    color: $persianGreen;
    font-size: 15px;
}
