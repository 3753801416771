@import '../../assets/main';

.Logo {
  max-width: 200px;
  max-height: 60px;
}

.TextBox {
  label {
    color: $white !important;
  }

  input {
    color: $white !important;
  }

  fieldset {
    border: 1px solid $nobel !important;
  }
}

.TextBoxOTP {
  width: 60px !important;
  height: 60px !important;

  label {
    color: $white !important;
  }

  input {
    color: $white !important;
    padding: 0px 25px !important;
    width: 60px !important;
    height: 60px !important;
  }

  fieldset {
    border: 1px solid $nobel !important;
  }
}

.ForgotBtn {
  background: linear-gradient(
    168.35deg,
    $IrisBlue 139.58%,
    $Swamp 592.83%
  ) !important;
  font-weight: 600 !important;
}

.otpBox > input {
  width: 60px !important;
  height: 60px;
  background-color: rgba(35, 35, 35, 0.8);
  color: $white;
  border: 2px solid $nobel !important;
  margin: 10px;
}

.otpBox input:focus {
  border: 2px solid $Tradewind !important;
}

.focusStyle {
  outline: cadetblue;
}
