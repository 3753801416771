@import '../../../assets/main';

.ahura-study-search {
  justify-content: center;
  .Fieldinput input {
    width: 358px;
    height: 52px;
    border-radius: 52px;
    margin-right: 20px;
    &::placeholder {
      font-size: 18px;
      font-weight: 500;
    }
  }



  .btn-primary {
    border: none !important;
    min-width: 120px;
    outline: none;
    height: 52px;
    border-radius: 52px;
    background-color: $persianGreen;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
}