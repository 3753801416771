// Define variables for colors
$White: #ffffff;
$brigthGray: #e3eeed;
$black: #000000;
$Tradewind: #51aa9a;
$silver: #bcbcbc;
$tuna: #3a3a45;
$chineseSilver: #cacaca;
$WhiteSmoke: #f8f8f8;
$russianBlack: rgba(0, 0, 0, 0.147318);
$lightGray: #d0d0d0;
$grey: #818181;
$gainsboro: #dddddd;
$nobel_1: #969696;
$martinique: #3d3c40;
$gray90: #e5e5e5;
$persianGreen: #00a89a;
$carmine: #a80028;
$white: white;
$cyprus: #00443e;
$pineGreen: #00766c;
$surfiGreen: #028277;
$nobel: #9a9a9a;
$islamicGreen: #00a811;
$Cerulean: #006ca8;
$jazzberryJam: #a80051;
$Crusta: #ffa372;
$blueViolet: #853fda;
$Firefly: #3a4545;
$Swamp: #141d1d;
$perfume: #c2a0ed;
$IrisBlue: #00c0b5;
$BlueWhale: #083142;
$DarkGray: #a9a9a9;
$santasGrey: #9595a1;
$eclipse: #3e3e3e;
$lightCyan: #e7f6fa;
$ghostWhite: #f3f3f4;
$DimGray: #696969;
$WhiteSmoke: #f2f2f2;
$PersianGreen: #00a89a;
$RobinsEggBlue: #00d3ca;
$TurquoiseBlue: #66e5db;
$HitGrey: #9ba1a1;
$Silver: #c4c4c4;
$CarrotOrange: #fb8c26;
$Salem: #178751;
$RadicalRed: #fb3549;
$eclipse: #3e3e3e;
$lightCyan: #e7f6fa;
$ghostWhite: #f3f3f4;
$santasGrey: #9595a1;
$salem: #178751;
$slateGrey: #757f88;
$freeSpeechRed: #b70b0b;
$burntOrange: #fb7f3d;
$mandysPink: #f5b18c;
$veryLightGrey: #c7c7c7;
$brightTurquoise: #1cebdf;
$atlantis: #93ea46;
$robinsEggBlue: #00d3ca;
$gunPowder: #57575c;
$dimGrey2: #6c6c6c;
$charcoal: #494949;
$nobel: #979797;
$darkGoldenrod: #a88300;
$indigo: #6800a8;
$LightSlateBlue: #7b61ff;
$BlueChalk: #e5d8f3;
$PurpleHeart: #5c14b8;
$PattensBlue: #dbe3eb;
$BlueViolet: #913dfb;
$Gainsboro: #dbdbdb;
$Nero: #1d1d1d;
$spray: #87d4e4;
$echoBlue: #a3aed0;
$eclipse: #3e3e3e;
$lightCyan: #e7f6fa;
$ghostWhite: #f3f3f4;
$santasGrey: #9595a1;
$salem: #178751;
$slateGrey: #757f88;
$freeSpeechRed: #b70b0b;
$SurfieGreen: #028277;
$ParisDaisy: #f6e655;
$lightCyan: #e5f9f8;
$DeepSkyBlue: #00b0ff;
$Crimson: #cb0736;
$PersianRed: #d32f2f;
$putty: #c7ab6f;
$lightYellow: #ffa828;
$java: #259292;
$azure: #f0fffe;
$mabel: #c0f2ed;
$lightSeaGreen: #25aa9f;
$tranquil: #e5f2f1;
$darkCyan: #57bb8a;

// New Colors for Overlay
$blue-color: #2196F3;
$green-color: #4CAF50;
$red-color: #F44336;

// FONT SIZE
$font_12: 12px;
$font_18: 18px;
$font_20: 20px;
$font_24: 24px;

// FONT_FAMIY
$font_Poppins_sans_serif: 'Poppins', sans-serif;
$font_SF_Pro_Display: 'SF Pro Display';
