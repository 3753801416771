@import "../../assets/main";

.MainContainer {
  background: $black;
  height: 100vh;
  overflow: auto;
}

.BoxContainer {
  background: #232323cc;
  margin: 50px auto;
}
