@import '../../../assets/main';

.QuizMainContainer {
  background: $white;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}

.DisableButton {
  background: $ghostWhite !important;
  border: 1px solid $santasGrey !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  color: $santasGrey !important;
  margin-top: 15px !important;
  padding: 6px 20px !important;
}

.Button {
  width: 200px !important;
}

.DropDown {
  border: 1px solid $persianGreen !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  width: 182px !important;
  color: $persianGreen !important;
  div {
    padding: 6px 12px !important;
  }

  fieldset {
    display: none !important;
  }
}
.questionsSummary {
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.12);
  min-height: 500px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.questionsCard {
  font-weight: 600 !important;
}

.divider {
  min-height: 250px !important;
  max-height: auto;
  align-self: stretch;
  color: #dbe3eb;
}

.warning {
  color: #b70b0b;
}

.correct {
  color: #00a89a;
}
.ScoreContainer {
  background-color: $white;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  height: 460px;
}

.scoreModal {
//   height: 100px;
}

.ScoresEarned {
  background-color: green !important;
  text-align: center !important;
}

.chartContainer {
  height: 250px;
  width: 250px;
}

.modalWrapper {
  width: 60vw;
  // height: 60vh;
  background-color: $white;
}
