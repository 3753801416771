@import '../../assets/main';

.MainContainer {
  overflow-y: scroll;
  height: calc(100vh - 8vh);
}

.LandingPlayer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $white;
}

.ClosePlayer {
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 50%;
  background-color: $black;
  cursor: pointer;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
}
