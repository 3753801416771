@import '../../../../assets/main';

.pageHeaderContainer {
    justify-content: space-between;
    background-color: $persianGreen;
    height: 100px; 
}

.SectionTitle {
    color: $white;
}

.SectionSubTitle {
    color: $white;
    font-size: 16px;
}

.TopicsContainer {
    // margin-left: 7vw;
    height:15vh;
}

.NetworkTopics {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 2vw;
}

.NetworkTopicsIcon {
    height:35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $white;
}

.NetworkTopicTitle {
    color: $white
}