// @import '../../../../a' ;

.panel {
  position: relative !important;
  top: 58px !important;
  left: 50px !important;
  height: calc(100vh - 58px) !important;
  bottom: 0px !important;
  width: calc(100vw - 50px) !important;
}

.panelNavbarChildens {
  position: absolute !important;
  right: 0px !important;
  left: 50px !important;
}
