@import '../../assets/main';
.navbarContainer {
  position: absolute !important;
  left: 0 !important;
  background: $white !important;
  height: 100% !important;
  width: 50px !important;
}
.navbar-wrapper {
  display: flex !important;
  margin-top: 0.375rem !important;
  margin-bottom: 0.375rem !important;
  flex-direction: column !important;
}
