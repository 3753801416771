@import '../../../../../assets/main';

.badgeClass {
  margin-right: 0.5rem !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  font-weight: 700 !important;
  text-align: center !important;
  height: 1.5rem !important;
  border-radius: 0.5rem !important;
  min-width: 43px !important;
  background-color: $nobel_1 !important;
}

.subItemName {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  font-weight: 400 !important;
  line-height: 1.25rem !important;
}

.listItemsWrapper {
  margin-left: 5rem !important;
}
